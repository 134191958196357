import CompanyInfo from "@cospex/client/components/CompanyInfo";
import LanguageSelect from "@cospex/client/components/LanguageSelect";
import MarketingEmail from "@cospex/client/components/MarketingEmail";
import { useGlobalConfig } from "@cospex/client/hooks/useGlobalConfig";
import {
  Box,
  Container,
  Divider,
  Link,
  Typography,
  styled,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import PaymentProcessorImg from "../../img/payment-processor.svg";
import Logo from "../Logo";
import useTranslation from "./dictionary";
import { useLanguage } from "@cospex/client/helpers/dictionary";

const FooterContainer = styled(Box)`
  padding-top: 6rem;
  padding-bottom: 1.5rem;
  text-align: center;
  li {
    margin-bottom: 2rem;
  }
`;

export default function Footer() {
  const lang = useLanguage();
  const { t } = useTranslation();
  const { languages } = useGlobalConfig();
  return (
    <Container>
      <Grid container spacing={1} sx={{ pt: 4 }}>
        <Grid
          size={{
            xs: 12,
            sm: 6,
            md: 5,
          }}
        >
          <Box
            sx={{
              mb: 2,
            }}
          >
            <Logo
              sx={{
                justifyContent: "flex-start",
              }}
            />
          </Box>
          <Typography
            variant="body2"
            sx={{ maxWidth: 300, pb: "1rem", color: "grey.500" }}
          >
            {t("footer-description")}
          </Typography>
          <LanguageSelect options={languages} />
          <Box sx={{ mt: "2rem" }}>
            <Box
              component="img"
              sx={{
                maxWidth: 180,
                ...(lang === "fr"
                  ? {}
                  : {
                      clipPath: "inset(0 calc(100% - 140px) 0 0)",
                      transform: "translateX(+20px)",
                    }),
              }}
              src={PaymentProcessorImg}
            />
          </Box>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 6,
            md: 3,
          }}
        >
          <ul>
            <li>
              <Link target="_blank" href="/cookies">
                {t("footer-link-cookies")}
              </Link>
            </li>
            <li>
              <Link target="_blank" href="/privacy">
                {t("footer-link-privacy")}
              </Link>
            </li>
            <li>
              <Link target="_blank" href="/terms">
                {t("footer-link-terms")}
              </Link>
            </li>
            <li>
              <Link target="_blank" href="/contact">
                {t("footer-link-contact")}
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 6,
            md: 4,
          }}
        >
          <ul>
            <li>
              <Link target="_blank" href="/pricing">
                {t("footer-link-pricing")}
              </Link>
            </li>
            <li>
              <Link target="_blank" href="/refunds">
                {t("footer-link-refunds")}
              </Link>
            </li>
            <li>
              <Link target="_blank" href="/login">
                {t("footer-link-login")}
              </Link>
            </li>
            <li>
              <Link target="_blank" href="/unsubscribe">
                {t("footer-link-unsubscribe")}
              </Link>
            </li>
          </ul>
          <MarketingEmail />
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 6,
            md: 3,
          }}
        ></Grid>
      </Grid>
      <FooterContainer>
        <Divider light sx={{ mb: 1 }} />
        <Typography variant="body2" color="text.secondary" mb={1.5}>
          Copyright {" © "}
          {new Date().getFullYear()} {__APP_NAME__}{" "}
        </Typography>
        <CompanyInfo color="#586174" />
      </FooterContainer>
    </Container>
  );
}
